import { AuthenticationResult } from "@azure/msal-browser";
import * as _ from "lodash";
import { TextField } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { User } from "../../../common/types";
import { useResponseAccessToken } from "../../../hooks/useResponseAccessToken";
import { getUsers } from "../../../services/capacityService";
import { Link } from "react-router-dom";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";

export default function UsersList() {
  const { getAccessToken } = useResponseAccessToken();
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const customSnackbar = useCustomSnackbar();
  const [error, setError] = useState(false);

  useEffect(() => {
    let ignore = false;
    const getData = async () => {
      var token: AuthenticationResult | null = await getAccessToken();

      if (token !== null && !ignore) {
        try {
          setError(false);
          const users = await getUsers(token.accessToken);
          setUsers(users);
          setFilteredUsers(_.cloneDeep(users));
        } catch (error) {
          customSnackbar.showMessage("Cannot read users!", "error");
          setError(true);
        }
      }
    };

    getData();
    return () => {
      ignore = true;
    };
  }, [customSnackbar, getAccessToken]);

  const columns: GridColDef[] = [
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params: GridRenderCellParams<string>) => (
        <Link className="Link" to={`/details/${params.row.id}`}>
          {params.value}
        </Link>
      ),
    },
    { field: "name", headerName: "First name", flex: 1 },
    { field: "familyName", headerName: "Last name", flex: 1 },
  ];

  const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilteredUsers(
      users.filter((user) => user.email.includes(event.currentTarget.value))
    );
  };

  return (
    <>
      {users.length > 0 && (
        <>
          <div className="TableWrapper">
            <div className="Filter">
              <TextField
                onChange={handleFilter}
                id="standard-basic"
                label="Filter"
                variant="standard"
              />
            </div>

            <div
              style={{
                height: 700,
                width: "100%",
              }}
            >
              <DataGrid
                rows={filteredUsers}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        </>
      )}
      {users.length === 0 && !error && <>Loading...</>}
      {error && <>Error</>}
    </>
  );
}
