import { useNavigate } from "react-router";

export function Header() {
  let navigate = useNavigate();

  return (
    <h1 className="Header" onClick={() => navigate("/")}>
      Forte Capacity Manager
    </h1>
  );
}
