import { Grid, InputLabel } from "@mui/material";
import { UserDetails } from "../../../common/types";

export const ReadOnlyData: React.FC<{ userDetails: UserDetails }> = ({
  userDetails,
}) => {
  return (
    <div className="DetailsColumnLeft">
      <h2>User details</h2>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputLabel
            sx={{ color: "white", textAlign: "right" }}
            id="standard-basic"
            variant="standard"
          >
            Name:
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="standard-basic" variant="standard">
            {userDetails.name}
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <InputLabel
            sx={{ color: "white", textAlign: "right" }}
            id="standard-basic"
            variant="standard"
          >
            Family name:
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="standard-basic" variant="standard">
            {userDetails.familyName}
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <InputLabel
            sx={{ color: "white", textAlign: "right" }}
            id="standard-basic"
            variant="standard"
          >
            Email:
          </InputLabel>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="standard-basic" variant="standard">
            {userDetails.email}
          </InputLabel>
        </Grid>
      </Grid>
    </div>
  );
};
