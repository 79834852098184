export const TypesOfContract = ["Etat", "B2B", "B2Bm", "UZ"]

export type TypeOfContract = typeof TypesOfContract[number]

export interface User {
  id: string;
  name: string;
  familyName: string;
  email: string;
}

export interface CapacityException {
  billableCapacity: number | null;
  dailyCapacity: number | null;
  from: string | null;
  to: string | null;
}

export interface UserCapacity {
  billableCapacity: number | null;
  dailyCapacity: number | null;
  typeOfContract: TypeOfContract | null;
  harvestAccountId: number | null;
  harvestId: number | null;
  capacityExceptions: CapacityException[];
}

export interface UserDetails extends UserCapacity {
  id: string;
  name: string;
  familyName: string;
  email: string;
}
