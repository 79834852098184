import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
} from "@mui/material";

interface SimpleDialogProps extends DialogProps {
  title: string;
  cancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  confirm: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const SimpleDialog: React.FC<SimpleDialogProps> = ({
  title,
  cancel,
  confirm,
  ...props
}) => (
  <Dialog {...props}>
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button
        onClick={(e) => {
          cancel(e);
        }}
      >
        Cancel
      </Button>
      <Button
        onClick={(e) => {
          confirm(e);
        }}
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
