import axios from "axios";
import { User, UserCapacity, UserDetails } from "../common/types";

export const getUsers = async (token: string): Promise<User[]> => {
  const { data } = await axios.get<User[]>(
    `${process.env.REACT_APP_BACKEND_URL}/api/GetUsers`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const getUserDetails = async (
  token: string,
  id: string
): Promise<UserDetails> => {
  const { data } = await axios.get<UserDetails>(
    `${process.env.REACT_APP_BACKEND_URL}/api/GetUsersDetails?id=${id}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const putUserDetails = async (
  token: string,
  id: string,
  userCapacity: UserCapacity
): Promise<void> => {
  await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/api/putUserDetails?id=${id}`,
    userCapacity,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
