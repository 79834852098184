import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!.toString(),
    authority: process.env.REACT_APP_AUTHORITY!.toString(),
    redirectUri: window.location.origin + process.env.PUBLIC_URL ?? "",
  },
};

export const loginRequest: PopupRequest = {
  scopes: process.env.REACT_APP_SCOPE!.split(","),
};
