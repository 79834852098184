import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserDetails } from "../../common/types";
import { ReadOnlyData } from "./components/ReadOnlyData";
import { CapacityEditor } from "./components/CapacityEditor";
import { AuthenticationResult } from "@azure/msal-browser";
import { getUserDetails } from "../../services/capacityService";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import useCustomSnackbar from "../../hooks/useCustomSnackbar";

export default function Details() {
  let { id } = useParams();
  const [userDetails, setUserDetails] = useState<UserDetails>();
  const { getAccessToken, canAccuireToken } = useResponseAccessToken();
  const customSnackbar = useCustomSnackbar();

  useEffect(() => {
    const getData = async () => {
      if (canAccuireToken) {
        var token: AuthenticationResult | null = await getAccessToken();

        if (token !== null && id) {
          try {
            const user = await getUserDetails(token.accessToken, id);
            setUserDetails(user);
          } catch (error) {
            customSnackbar.showMessage("Cannot read user!", "error");
          }
        }
      }
    };

    getData();
  }, [getAccessToken, canAccuireToken, customSnackbar, id]);

  return (
    <div className="Details">
      <div className="DetailsInner">
        <Grid container spacing={12}>
          <Grid item md={6} xs={12}>
            <div className="MaxWidth DetailsItem">
              {userDetails && <ReadOnlyData userDetails={userDetails} />}
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className="MaxWidth DetailsItem">
              {userDetails && <CapacityEditor userDetails={userDetails} />}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
