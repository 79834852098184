import "./App.css";
import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { RequireLogin } from "./RequireLogin";
import { PublicClientApplication } from "@azure/msal-browser";
import { Outlet, Route, Routes } from "react-router";
import Details from "./pages/details/Details";
import { Header } from "./Header";
import Home from "./pages/home/Home";
import ModalProvider from "mui-modal-provider";
import { SnackbarProvider } from "material-ui-snackbar-provider";
import { CustomSnackbar } from "./common/CustomSnackbar";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <React.StrictMode>
      <ThemeProvider theme={darkTheme}>
        <ModalProvider>
          <SnackbarProvider
            SnackbarProps={{ autoHideDuration: 4000 }}
            SnackbarComponent={CustomSnackbar}
          >
            <MsalProvider instance={msalInstance}>
              <RequireLogin>
                <Routes>
                  <Route
                    element={
                      <div className="App">
                        <Header />
                        <Outlet />
                      </div>
                    }
                  >
                    <Route path="/details/:id" element={<Details />} />
                    <Route path="/" element={<Home />} />
                  </Route>
                </Routes>
              </RequireLogin>
            </MsalProvider>
          </SnackbarProvider>
        </ModalProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
