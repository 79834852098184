import { Button, IconButton, TextField, MenuItem } from "@mui/material";
import { TypesOfContract, UserCapacity, UserDetails } from "../../../common/types";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { FieldArray, Formik } from "formik";
import { useNavigate } from "react-router";
import { useResponseAccessToken } from "../../../hooks/useResponseAccessToken";
import { putUserDetails } from "../../../services/capacityService";
import { AuthenticationResult } from "@azure/msal-browser";
import { useCallback, useEffect, useState } from "react";
import { useModal } from "mui-modal-provider";
import { SimpleDialog } from "../../../common/SimpleDialog";
import * as _ from "lodash";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";

export const CapacityEditor: React.FC<{ userDetails: UserDetails }> = ({
  userDetails,
}) => {
  let navigate = useNavigate();
  const { getAccessToken } = useResponseAccessToken();
  const [userBody, setUserBody] = useState<UserCapacity>();
  const [dialogSent, setDialogSent] = useState<boolean>();
  const { showModal } = useModal();
  const customSnackbar = useCustomSnackbar();

  const handleSubmit = useCallback(async () => {
    var token: AuthenticationResult | null = await getAccessToken();
    if (token !== null && userBody) {
      try {
        await putUserDetails(token.accessToken, userDetails.id, userBody);
        customSnackbar.showMessage("User saved!", "success");
      } catch (error) {
        customSnackbar.showMessage("Cannot save user!", "error");
      }
    }
  }, [customSnackbar, getAccessToken, userBody, userDetails.id]);

  const handleOpenConfirmationDialog = useCallback(() => {
    const modal = showModal(SimpleDialog, {
      title: "Are you sure, you want to modify this user?",
      confirm: () => {
        handleSubmit();
        modal.hide();
        setDialogSent(false);
      },
      cancel: () => {
        modal.hide();
      },
    });
  }, [handleSubmit, showModal]);

  useEffect(() => {
    if (userBody && dialogSent) {
      handleOpenConfirmationDialog();
    }
  }, [userBody, dialogSent, handleOpenConfirmationDialog]);

  const parseDate = (date: string | null): string | null => {
    try {
      if (!date || date.toString() === "Invalid Date") {
        return null;
      }
      const dateFormated = new Date(
        Date.parse(
          String(date).substring(0, String(date).indexOf("GMT") - 1) +
            " GMT+0000"
        )
      );
      return dateFormated.toISOString().split("T")[0];
    } catch (error) {
      return null;
    }
  };

  return (
    <div className="DetailsColumnRight">
      <Formik
        initialValues={{
          billableCapacity: userDetails.billableCapacity,
          dailyCapacity: userDetails.dailyCapacity,
          typeOfContract: userDetails.typeOfContract,
          harvestAccountId: userDetails.harvestAccountId,
          harvestId: userDetails.harvestId,
          capacityExceptions: userDetails.capacityExceptions,
        }}
        onSubmit={(form) => {
          setDialogSent(true);
          setUserBody(_.cloneDeep(form));
        }}
      >
        {({ values, handleSubmit, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="CapcityForm">
              <h2>User capacity</h2>
              <div className="CapacityFormInputs">
                <TextField
                  sx={{ marginRight: "10px" }}
                  id="standard-basic"
                  label="Billable Capacity"
                  variant="standard"
                  value={values.billableCapacity ?? ""}
                  onChange={handleChange}
                  name="billableCapacity"
                  type="number"
                />
                <TextField
                  sx={{ marginRight: "10px" }}
                  id="standard-basic"
                  label="Daily Capacity"
                  variant="standard"
                  value={values.dailyCapacity ?? ""}
                  onChange={handleChange}
                  name="dailyCapacity"
                  type="number"
                />
                <TextField
                  select
                  sx={{ marginRight: "10px" }}
                  id="standard-basic"
                  label="Type of Contract"
                  variant="standard"
                  value={values.typeOfContract ?? ""}
                  onChange={handleChange}
                  name="typeOfContract"
                >
                  {TypesOfContract.map((value) => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{ marginRight: "10px" }}
                  id="standard-basic"
                  label="Harvest id"
                  variant="standard"
                  value={values.harvestId ?? ""}
                  onChange={handleChange}
                  name="harvestId"
                  type="number"
                />
                <TextField
                  sx={{ marginRight: "10px" }}
                  id="standard-basic"
                  label="Harvest account id"
                  variant="standard"
                  value={values.harvestAccountId ?? ""}
                  onChange={handleChange}
                  name="harvestAccountId"
                  type="number"
                />
              </div>
              <div>
                <FieldArray
                  name="capacityExceptions"
                  render={(arrayHelpers) => (
                    <div className="CapcityException">
                      <br />
                      <div>
                        Capacity Exceptions:
                        <IconButton
                          onClick={() =>
                            arrayHelpers.push({
                              billableCapacity: null,
                              dailyCapacity: null,
                              from: null,
                              to: null,
                            })
                          }
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <AddIcon />
                        </IconButton>
                      </div>

                      {values.capacityExceptions &&
                        values.capacityExceptions.map((value, index) => (
                          <div key={`exception_${index}`}>
                            <TextField
                              sx={{ marginRight: "10px", maxWidth: "150px" }}
                              id="standard-basic"
                              label="Billable Capacity"
                              variant="standard"
                              value={
                                values.capacityExceptions[index]
                                  ?.billableCapacity ?? ""
                              }
                              onChange={handleChange}
                              name={`capacityExceptions.${index}.billableCapacity`}
                              type="number"
                            />
                            <TextField
                              sx={{ marginRight: "10px", maxWidth: "150px" }}
                              id="standard-basic"
                              label="Daily Capacity"
                              variant="standard"
                              value={
                                values.capacityExceptions[index]
                                  ?.dailyCapacity ?? ""
                              }
                              onChange={handleChange}
                              name={`capacityExceptions.${index}.dailyCapacity`}
                              type="number"
                            />
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Date from"
                                inputFormat="yyyy-MM-dd"
                                value={
                                  values.capacityExceptions[index]?.from ?? null
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    `capacityExceptions.${index}.from`,
                                    parseDate(value),
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      marginRight: "10px",
                                      maxWidth: "150px",
                                    }}
                                    variant="standard"
                                    name={`capacityExceptions.${index}.from`}
                                    {...params}
                                  />
                                )}
                              />
                              <DatePicker
                                label="Date to"
                                inputFormat="yyyy-MM-dd"
                                value={
                                  values.capacityExceptions[index]?.to ?? null
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    `capacityExceptions.${index}.to`,
                                    parseDate(value),
                                    true
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      marginRight: "10px",
                                      maxWidth: "150px",
                                    }}
                                    variant="standard"
                                    name={`capacityExceptions.${index}.to`}
                                    {...params}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                            <IconButton
                              sx={{ marginTop: "10px" }}
                              color="primary"
                              aria-label="remove"
                              component="label"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </div>
                        ))}
                    </div>
                  )}
                />
              </div>
            </div>

            <Button
              onClick={() => navigate("/")}
              sx={{
                backgroundColor: "#ff0000",
                ":hover": {
                  backgroundColor: "#be4d25",
                },
                marginTop: "10px",
              }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              sx={{ marginTop: "10px", marginLeft: "10px" }}
              variant="contained"
              type="submit"
            >
              Save
            </Button>
          </form>
        )}
      </Formik>
    </div>
  );
};
